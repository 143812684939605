<template>
  <div class="mt-2">
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <!-- Table Top -->
          <b-col xl="12" md="12" sm="12" cols="12">
            <b-row class="mb-2">
              <!-- Per Page -->
              <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Show</label>
                <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                <label>entries </label>
              </b-col>

              <b-col cols="12" md="5">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input style="width:60%" id="filter-input" v-model="filter" class="d-inline-block mr-1" type="search" placeholder="Search..." />
                  <b-button style="width:40%" @click="createSurvey()" block class="float-right" variant="warning">
                    Create Survey
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              ref="refSurveysTable"
              class="position-relative"
              :items="listQuestionnaires"
              :filter="filter"
              :filter-included-fields="filterOn"
              filter-debounce="100"
              selectable
              select-mode="single"
              responsive
              :small="false"
              :fields="inHeaders"
              show-empty
              empty-text="No matching records found"
              @row-selected="onRowSelected"
              :current-page="currentPage"
              :per-page="perPage"
            >
              <template #cell(title)="data">
                <span class="font-weight-bold">
                  {{ data.item.title ? data.item.title : 'N/A' }}
                </span>
              </template>
              <template #cell(numberOfGroups)="data">
                <span class="font-weight-bold">
                  {{ data.item.groups.length > 0 ? data.item.groups.length : 0 }}
                </span>
              </template>
              <template #cell(numberOfQuestions)="data">
                <span class="font-weight-bold">
                  {{ data.item.questions.length > 0 ? data.item.questions.length : 0 }}
                </span>
              </template>

              <template #cell(status)="data">
                <b-badge v-if="data.item.status == 'A'" pill :variant="`light-success`" class="text-capitalize">
                  Active
                </b-badge>

                <b-badge v-if="data.item.status == 'P'" pill :variant="`light-danger`" class="text-capitalize">
                  Passive
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'settings-surveyDetail',
                      params: { id: data.item.id },
                    }"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details </span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="copySurvey(data.item.id, data.item.title)">
                    <feather-icon icon="CopyIcon" />
                    <span class="align-middle ml-50">Duplicate </span>
                  </b-dropdown-item>

                  <!-- <b-dropdown-item @click="deleteSurvey(data.item.id)">
                    <feather-icon icon="DeleteIcon" />
                    <span class="align-middle ml-50">Delete Survey</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import { BFormFile } from "bootstrap-vue";
import { BImg, BPagination, BFormRating, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../../settingsStoreModule';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import moment from 'moment';
import _ from 'lodash';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Ripple from 'vue-ripple-directive';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BPagination,
    BFormRating,
    moment,
    vSelect,
    flatPickr,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.getQuestionnaires();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      edit: null,
      filter: null,
      filterOn: ['title', 'numberOfGroups', 'numberOfQuestions', 'status'],
      perPage: localStorage.getItem('surveyShow') ?? 10,
      loading: false,
      items: [],
      where: router.currentRoute.name,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      totalRows: 0,
      listQuestionnaires: [],
      inHeaders: [
        {
          key: 'title',
          label: 'Survey Name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'numberOfGroups',
          label: 'Number of Groups',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },

        {
          key: 'numberOfQuestions',
          label: 'Number of Questions',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'status',
          label: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      colWidthFactor: null,
      tempStatusOptions: [{ label: 'Draft', value: 'A' }],
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('MM-DD-YYYY');
      }
    },
    createSurvey() {
      router.push({ name: 'settings-createSurvey' });
    },
    onRowSelected(item) {
      if (this.$Can('see_answer')) {
        router.push({
          name: 'settings-surveyDetail',
          params: { id: item[0].id },
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getQuestionnaires() {
      this.loading = true;
      this.listQuestionnaires = [];
      store
        .dispatch('settings/fetchQuestionnaires')
        .then((res) => {
          this.totalRows = res.data.length;
          res.data.forEach((item) => {
            let obj = {
              id: item.id,
              title: item.title,
              gradeLevels: item.gradeLevels,
              status: item.status,
              groups: [],
              questions: [],
            };
            if (item.groups.length > 0) {
              item.groups.forEach((group) => {
                obj.groups.push(group);
                if (group.questions.length > 0) {
                  group.questions.forEach((question) => {
                    obj.questions.push(question);
                  });
                }
              });
            }
            this.listQuestionnaires.unshift(obj);
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSurvey(id) {
      const self = this;
      this.$swal({
        title: 'Are you sure you want to delete the survey?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          // store
          //   .dispatch('settings/deleteSurvey', id)
          //   .then((res) => {
          //     self.getQuestionnaires();
          //     self.$swal.fire('Deleted!', 'Survey has been deleted.', 'success');
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
        }
      });
    },

    copySurvey(id, title) {
      const self = this;

      const currentDate = new Date();
      const randomTimeNumbers = currentDate.getDay() + '' + currentDate.getHours() + '' + currentDate.getMinutes() + '' + currentDate.getSeconds();

      this.$swal({
        title: 'Duplicate Survey',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, duplicate  it!',
        input: 'text',
        inputPlaceholder: 'Enter survey name',
        inputValue: title + ' - copy_' + randomTimeNumbers,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to enter a survey name';
          }
        },

        preConfirm: (val) => {
          return axiosIns
            .post(`copyNameCheck`, { surveyTitle: val })
            .then((res) => {
              if (res.data.existing == 1) {
                throw new Error('This ' + title + ' name is existing');
              } else {
                return true;
              }
            })
            .catch((error) => {
              this.$swal.showValidationMessage(`${error.message}`);
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const surveyName = document.querySelector('.swal2-input').value;

          store
            .dispatch('settings/copyQuestionnaire', { surveyId: id, surveyTitle: surveyName })
            .then((res) => {
              let copiedId = res.data.id;
              self
                .$swal({
                  title: 'Copied!',
                  text: 'New questionnaire duplicate successfully created',
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  showCancelButton: false,
                })
                .then((result) => {
                  //  window.location.reload();
                  this.getQuestionnaires();
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('surveyShow', val);
      },
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
